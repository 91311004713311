<template>
  <div class="recommendMoneyNet">
    <div v-title
         data-title="推荐币网"></div>
    <div class="Navbar">
      <img @click="gotoback"
           :src="imgUrl + 'left-arrow2.png'" />
      <div class="title">推荐币网</div>
    </div>

    <div class="img-box">
      <div class="img-boxs"><img :src="`${imgUrl}usdt/title.png`"></div>

      <ul>
        <!-- <li @click="gotolink(3)"><img :src="imgUrl + 'recommendMoneyNet2.png'" /></li>
        <li @click="gotolink(5)"><img :src="imgUrl + 'recommendMoneyNet6.png'" /></li>
        <li @click="gotolink(2)"><img :src="imgUrl + 'recommendMoneyNet5.png'" /></li> -->
        <li @click="gotolink(6)"><img :src="imgUrl + 'recommendMoneyNet7.png'" /></li>
        <li @click="gotolink(1)"><img :src="imgUrl + 'recommendMoneyNet4.png'" /></li>
        <li @click="gotolink(2)"><img :src="`${imgUrl}/usdt/item4-6.jpg`" /></li>

        <!-- <li @click="gotolink(6)"><img src="../assets/img/usdt/item4-4.png" /></li> -->

        <!-- <li @click="gotolink(4)"><img :src="imgUrl + 'recommendMoneyNet3.png'" /></li> -->

      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }

  },
  mounted() {
  },
  methods: {
    gotoback() {
      uni.postMessage({
        data: {
          tabbarIndex: 1
        }
      });
      uni.navigateBack({});
    },

    gotolink(index) {
      if (Object.keys(res)[0] === 'h5') {
        if (index == 6) {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'openUrl',
                openUrl: 'https://www.okex.com/cn/download',
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else if (index == 2) {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'openUrl',
                openUrl: 'https://www.gate.io/cn/',
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else if (index == 1) {
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'openUrl',
                openUrl: 'https://www.zb.com/',
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        }
      } else {
        if (index === 1) {
          /*  window.open('https://www.zb.com/', '_blank') */
          uni.postMessage({
            data: {
              openUrl: 'https://www.zb.com/'
            }
          });
        } else if (index === 2) {
          /* window.open('https://www.gate.io/cn/', '_blank') */
          uni.postMessage({
            data: {
              openUrl: 'https://www.gate.io/cn/'
            }
          });
        } else if (index === 6) {
          /* window.open(' https://www.mexc.com/zh-CN', '_blank') */
          uni.postMessage({
            data: {
              openUrl: 'https://www.okex.com/cn/download'
            }
          });
        }
      }

    }
  }
}
</script>

<style lang="less">
body {
  background-color: #f5f5f5;
}

ul,
li {
  list-style: none;
}

.recommendMoneyNet {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    background-color: #fff;
    padding: 0 20px;

    > img {
      height: 15px;
      width: 15px;
    }

    .title {
      flex: 1;
      text-align: center;
      color: #000;
    }
  }

  .img-box {
    background-color: var(--w-bgcolor);
    padding: 20px 0px;
    border-radius: 10px;
    margin: 10px;
    margin-top: 53px;

    .img-boxs {
      margin: 0 80px;

      > img {
        width: 100%;
      }
    }

    li {
      margin: 10px 15px;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>